@import 'utils';

.container {
  @include flex-column;
  justify-content: center;
}
.graph {
  padding: spacing(2) 0 spacing(6);
  @include flex-column;
  row-gap: spacing(4);
  @include up(tablet) {
    padding-top: spacing(6);
    display: grid;
    grid-template-columns: 30% auto;
    column-gap: spacing(10);
    row-gap: spacing(4);
  }

  span {
    text-align: left;
    @include up(tablet) {
      text-align: end;
    }
  }
}
.data {
  display: flex;
  gap: spacing(1);
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: var(--color-brand-gartner-blue);
  }
}

.legend {
  @include flex-column;
  gap: spacing(2);

  width: 100%;
  justify-content: flex-end;

  @include up(tablet) {
    flex-direction: row;
    gap: spacing(4);
  }
}

.label {
  display: flex;
  gap: spacing(3);
  align-items: center;
}

.label_color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.label_1 {
  background-color: var(--color-utility-error-100);
}

.label_2 {
  background-color: var(--color-brand-gartner-blue);
}

.label_3 {
  background-color: var(--color-brand-surf);
}

.bar_1 {
  background-color: var(--color-utility-error-50);
  border-bottom: 1px solid var(--color-utility-error-100);
  height: 44px;
  width: 100px;
}

.bar_2 {
  background-color: var(--color-neutral-300);
  border-bottom: 1px solid var(--color-brand-gartner-blue);
  height: 44px;
  width: 100px;
}

.bar_3 {
  background-color: var(--color-utility-success-50);
  border-bottom: 1px solid var(--color-brand-surf);
  height: 44px;
  width: 100px;
}
