@import 'utils';

.wrapper {
  background-color: var(--neutrals-01-white);
  box-shadow: var(--box-shadow-02-normal, 0px 8px 10.32px rgba(0, 40, 86, 0.08));
  color: var(--color-foreground-hex);
  gap: 2px;
  grid-area: nav-bar;
  height: 80px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;

  @include up(tablet) {
    gap: spacing(1);
  }
}

.content {
  align-items: center;
  display: grid;
  grid: 'menu logo cta' 80px / 48px auto auto;
  height: 80px;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 20px;
  width: 100%;

  @include up(mobile) {
    padding: 0;
  }
  @include up(tablet) {
    grid: 'menu logo cta' 80px / 48px auto 150px;
  }
}

.primary + * {
  margin-top: 80px;
}

.logo {
  color: var(--color-brand-gartner-blue);
  grid-area: logo;
  padding: spacing(6) 0;

  @include down(tablet) {
    svg {
      width: 130px;
    }
  }
}

.cta {
  align-items: center;
  display: inline-flex;
  gap: spacing(2);
  grid-area: cta;
  justify-content: flex-end;
}
