@import 'utils';

.tooltip {
  cursor: pointer;
  display: inline-flex;
  --arrow-offset: calc(var(--arrow-size) / 2);
  position: relative;
  --arrow-size: 8px;

  &:focus .tooltip_content {
    visibility: visible;
  }

  .tooltip_arrow {
    background-color: inherit;
    height: var(--arrow-size);
    left: calc(50% - var(--arrow-offset));
    position: absolute;

    top: calc(var(--arrow-offset) * -1);
    transform: rotate(45deg);

    width: var(--arrow-size);
  }

  svg {
    display: block;
  }
}

.tooltip_content {
  background-color: var(--color-product-text);
  border-radius: 2px;
  box-shadow: var(--box-shadow-xsmall);
  color: var(--color-background-hex);
  line-height: 24px;
  max-width: 360px;
  padding: spacing(3) spacing(4);
  position: absolute;
  top: calc(100% + var(--arrow-offset) + 2px);
  transform: translateX(-80%);
  width: max-content;
  z-index: 1;
}
