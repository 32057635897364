.stat {
  flex: 1;
  text-align: center;

  .stat_count {
    color: var(--section-heading-color);
    display: inline-block;
    font-size: 27px;
    margin-bottom: 10px;
  }
}
