@import 'utils';

.container {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  width: 100%;

  .main_content {
    display: flex;
    gap: spacing(4);

    .avatar_img {
      display: flex;
      flex-shrink: 0;
      height: 48px;
      width: 48px;
      align-self: center;

      span {
        font-size: 21px;
        line-height: 100%;
      }

      .avatar_badge {
        width: 14px;
        height: 14px;
        bottom: 0px;
        right: 0px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 0;

      p {
        color: var(--color-neutral-800);
      }

      .name {
        font-size: 16px;
        line-height: 24px;

        &:active {
          opacity: 0.3;
        }
      }

      .title {
        font-size: 14px;
        line-height: 22px;
      }

      .industry_and_location {
        display: none;
        font-size: 14px;
        line-height: 20px;
      }

      .connection_message_tablet {
        display: none;
      }
    }
  }
  .connection_message_phone {
    display: block;
    padding: spacing(3);
    background-color: var(--color-polar);
    white-space: pre-wrap;
    border-radius: 12px;
    color: var(--color-neutral-800);
  }
}

@include up(tablet) {
  .container {
    width: auto;

    .main_content .avatar_img {
      height: 120px;
      width: 120px;

      .avatar_badge {
        width: 30px;
        height: 30px;
        bottom: 4px;
        right: 4px;
      }

      span {
        font-size: 52px;
      }
    }

    .main_content {
      .info {
        gap: spacing(3);

        .name {
          font-weight: 400;
          font-size: 24px;
          line-height: 33px;
        }

        .title {
          font-size: 16px;
          line-height: 27px;
        }

        .industry_and_location {
          display: block;
        }

        .connection_message_tablet {
          display: block;
          border-radius: 4px;
          padding: spacing(4);
          white-space: pre-wrap;
          background-color: var(--color-polar);
          color: var(--color-neutral-800);
          font-style: italic;
        }
      }
    }
    .connection_message_phone {
      display: none;
    }
  }
}
