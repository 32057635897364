@import 'utils';

.root {
  align-items: center;
  display: flex;
  height: 100vh;
  inset: 0;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: 9000;
  @include up(phone) {
    padding: spacing(4) spacing(6);
  }
}

.overlay {
  align-items: center;
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 1;
  position: fixed;
}

.container {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: center;
  max-width: getWidth(phone);
  outline: 0;
  pointer-events: none;
  position: relative;
  width: 100%;

  @include up(tablet) {
    max-width: getWidth(tablet);
  }

  @include up(desktop) {
    max-width: getWidth(desktop);
  }

  .content {
    background-color: var(--color-background-hex);
    border-radius: 4px;
    height: 100%;
    margin: spacing(8);
    max-height: calc(100vh - 64px);
    pointer-events: all;
    @include flex-column;
    width: 100%;

    &.small {
      max-width: getWidth(phone);
    }

    &.medium {
      max-width: getWidth(tablet);
    }

    &.large {
      max-width: getWidth(desktop);
    }
  }
}
