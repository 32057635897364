@import 'utils';

.wrapper {
  gap: spacing(6);
}

.header {
  background: linear-gradient(var(--color-brand-gartner-blue), #316097);
  color: var(--color-white);
  padding: spacing(4);
}

.title {
  strong {
    margin: auto 0;
  }
  @include up(tablet) {
    display: flex;
  }
}

.powered_by {
  margin: 0;
  margin-left: auto;
  display: block;
  br {
    display: none;
  }
  sup {
    line-height: 0.9;
    font-size: x-small;
  }

  @include up(tablet) {
    text-align: right;
    br {
      display: initial;
    }
  }
}

.error_msg {
  text-align: center;
  @include up(tablet) {
    padding: spacing(25) 0;
  }
}
