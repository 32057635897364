@import 'utils';

.disclaimer {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.disclaimer_text {
  color: var(--color-utility-interaction-700);
  font-weight: bold;
}
