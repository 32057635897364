@import 'utils';

.main {
  display: flex;
  margin: spacing(10) auto;
  max-width: 1440px;
}


.breadcrumb {
  padding: 0 spacing(5);
  @include up(desktop) {
    padding: 0;
  }
}

.content {
  max-width: 100%;
  @include up(tablet) {
    margin: 0 auto;
    width: calc(100% - 320px);
  }
}

.side_bar {
  display: none;
  @include up(tablet) {
    --margin: 15px;
    display: block;
    height: calc(100vh - 100px);
    margin: 0 var(--margin) auto var(--margin);
    -ms-overflow-style: none;
    overflow-y: auto;
    position: sticky;
    scrollbar-width: none;
    top: 100px;
    width: 320px;
    z-index: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.comment_action {
  margin: spacing(8) 0;
}