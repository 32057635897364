@import 'utils';

.user_points {
  align-items: flex-end;
  display: flex;
  gap: spacing(1);
  white-space: nowrap;

  span {
    font-weight: 500;
  }
}
