@import 'utils';

.empty_state {
  color: var(-neutral-300);
  text-align: center;

  .heading {
    color: var(-neutral-300);
  }

  .learn_more {
    color: var(--color-brand-gartner-blue);
    font-weight: 600;
  }

  :not(:last-child) {
    margin-bottom: spacing(4);
  }
}
