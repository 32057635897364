@import 'utils';

.container {
  background-color: var(--color-neutral-100);
  margin-bottom: spacing(3);
  border-radius: 4px;
  display: grid;
  opacity: 1;
  grid-template-rows: 1fr;
  transition: grid-template-rows 400ms ease-in, opacity 400ms ease-out;

  .card_container {
    overflow: hidden;
  }

  .card_container_hidden {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.closed {
  grid-template-rows: 0fr;
  opacity: 0.1;
  margin-bottom: 0;
}

@include up(tablet) {
  .container {
    box-shadow: var(--box-shadow-02-large);
    margin-bottom: spacing(5);
    border-radius: 8px;
  }

  .closed {
    margin-bottom: 0;
  }
}
