@import 'utils';

.spinnerWrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  circle {
    fill: none;
    stroke: var(--button-primary-bg-color);
    stroke-width: 4px;
  }
}

.spinner {
  animation: loadingSpinner 1s linear infinite;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
