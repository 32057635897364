@import 'utils';

.wrapper {
  background-color: var(--color-background-hex);
  box-shadow: var(--box-shadow-02-normal);
  color: var(--color-brand-gartner-blue);
  display: flex;
  height: 80px;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;

  .content {
    display: flex;
    gap: spacing(4);
    max-width: 1440px;
    padding: 0 10px;
    width: 100%;
    @include up(tablet) {
      gap: spacing(6);
    }

    svg {
      display: block;
    }

    .logo {
      align-self: center;
      color: var(--color-brand-gartner-blue);
      min-width: 110px;

      svg {
        width: 100%;
      }
    }

    .menu_items {
      display: none;
      @include up(desktop) {
        display: flex;
        gap: spacing(3);
      }
    }
  }
}

.user_info {
  align-items: center;
  display: flex;
  gap: spacing(3);
  height: 100%;
  @include up(tablet) {
    gap: spacing(4);
  }

  .user_profile {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .vertical_divider {
    background-color: var(--color-foreground-hex);
    height: 32px;
    width: 1px;
  }

  .user_points {
    display: none;
    @include up(tablet) {
      column-gap: spacing(1);
      display: flex;
    }
  }
}
