@import 'utils';

.main {
  color: var(--color-product-text);
  margin: spacing(10) auto;
  padding: 0 spacing(6);
  @include up(tablet) {
    max-width: 1440px;
  }
}

.section_dark {
  background-color: var(--color-neutral-200);
  display: flex;
  justify-content: center;
  padding: spacing(10) 0;

  > section {
    @include up(tablet) {
      max-width: 1440px;
    }
  }
}

.section_omis {
  padding: 0 spacing(6);
  @include up(tablet) {
    max-width: 1440px;
  }
}

.omis_cards {
  display: grid;
  gap: spacing(9);
  grid-template-columns: auto;

  @include up(tablet) {
    gap: spacing(9) spacing(15);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include up(desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.header {
  margin-bottom: spacing(6);
  padding: spacing(6) 0;

  .disclaimer {
    @include flex-row;
    align-items: stretch;
    background-color: var(--color-neutral-200);
    gap: spacing(3);
    margin-top: spacing(4);
    padding: spacing(2) spacing(3);

    :nth-child(2) {
      flex: 1;
      line-height: 1.5;
    }
  }

  @include up(desktop) {
    @include flex-row;
    align-items: flex-start;
    > .title {
      flex: 1;
    }
    > .disclaimer {
      margin-top: 0;
      width: 650px;
    }
  }
}

.subheader {
  color: var(--color-neutral-600);
  display: inline-block;
  margin-bottom: spacing(2);

  .peer_driven_insights {
    text-transform: uppercase;
  }
}

.section {
  &:not(:last-child) {
    margin-bottom: spacing(6);
  }
}

.peer_community_sentiment,
.join_the_conversation,
.peer_software_reviews,
.topic_content {
  @include flex-column;
  gap: spacing(6);
  @include up(desktop) {
    @include flex-row;
  }
}

.peer_community_sentiment {
  margin-bottom: spacing(6);
  @include up(desktop) {
    .community_sentiment {
      flex-basis: 30%;
      height: 100%;
      @include up(tablet) {
        max-width: 424px;
      }
    }
    .community_confidence {
      flex: 1;
    }
  }
}

.peer_community_sentiment_title {
  margin-bottom: spacing(3);
  @include up(tablet) {
    margin-bottom: spacing(0);
  }
}

.peer_community_sentiment_header {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing(6);
  @include up(tablet) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.join_the_conversation,
.peer_software_reviews,
.topic_content {
  @include up(desktop) {
    > :nth-child(1) {
      flex: 1;
    }
    > :nth-child(2) {
      flex-basis: 30%;
    }
  }
}

.section {
  .active_ambassadors {
    @include up(desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.topic_feed {
  width: 100%;
}

.topic_content {
  @include up(desktop) {
    align-items: flex-start;
    gap: spacing(20);
    // Sticky
    > :nth-child(2) {
      flex-basis: 25%;
    }
    .topic_sidebar {
      position: sticky;
      top: 100px;
    }
  }
}

.topic_sidebar {
  > :not(:last-child) {
    margin-bottom: spacing(10);
  }
}

.link_to_post {
  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-200);
  }
}
