@import 'utils';

.item {
  list-style: none;
  padding-right: spacing(4);
  margin-bottom: spacing(3);

  a {
    display: flex;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding: spacing(1);
    padding-left: spacing(3);
    color: var(--color-brand-gartner-blue);

    span {
      display: block;
      padding-top: spacing(2);
      color: var(--color-black);
    }
  }

  img {
    object-fit: contain;
    border: 1px solid var(--color-neutral-300);
    padding: spacing(1);
  }

  @include up(tablet) {
    width: 27%;
    margin-bottom: 0;
  }
}
