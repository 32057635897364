@import 'utils';

.awards_modal {
  height: 80%;
}

.awards_list {
  justify-content: space-between;
  padding: 0 spacing(6);
}

.awards_count {
  margin-bottom: spacing(6);
}
