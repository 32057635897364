@import 'utils';

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: spacing(2);
  > :first-child::before {
    content: '';
    background-color: var(--color-brand-surf);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: spacing(1);
    position: relative;
    display: inline-block;
  }
}

.recent_activity {
  margin-right: spacing(1);
}
