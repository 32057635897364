@import 'utils';

.identified_expertise_modal {
  height: 80%;
}

.header {
  padding-bottom: spacing(6);
}

.body {
  padding-top: 0;
}

.message {
  margin-bottom: spacing(6);
}
