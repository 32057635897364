@import 'utils';

.allowed_contributors {
  display: flex;

  .tooltip {
    margin-left: 6px;
  }
}

.allowed_contributors .tooltip svg {
  @include down(tablet) {
    display: none;
  }
}
