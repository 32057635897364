@import 'utils';

.view_more {
  background-color: var(--color-neutral-400);
  border-radius: 300px;
  color: var(--color-foreground-hex);
  display: flex;
  font-weight: 500;
  margin-top: spacing(8);
  width: 100%;

  &:hover,
  &:active:not(.disabled) {
    background-color: var(--color-neutral-500);
  }

  @include up(desktop) {
    background-color: transparent;
    border-radius: 0;
    &:hover,
    &:active:not(.disabled) {
      background-color: transparent;
    }
  }
}
