@import 'utils';

.admin {
  @include flex-column;
  gap: spacing(4);
}

.admin_tools {
  color: var(--color-neutral-500);
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.demo_access {
  padding-left: spacing(4);
}

.user_data {
  @include flex-column;
  gap: spacing(4);
}

.user_username_and_email {
  @include flex-column;
  gap: spacing(1);
}

.user_name {
  color: var(--color-foreground-hex);
}

.user_email {
  color: var(--color-neutral-500);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.user_profile_image {
  background-color: var(--color-brand-gartner-blue);
  border-radius: 50%;
}

.user_dropdown {
  span {
    b {
      color: var(--color-product-text);

      &:hover {
        color: var(--color-utility-interaction-600);

        svg {
          color: var(--color-utility-interaction-600);
        }
      }
    }
  }
}

.private_communities_menu_item {
  padding-bottom: 0;
}
