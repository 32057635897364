@import 'utils';

.disclaimer {
  display: block;
  color: var(--color-utility-interaction-700);
  margin-top: spacing(5);

  svg {
    vertical-align: top;
  }
}
