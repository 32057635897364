@import 'utils';

.avatar {
  color: var(--neutrals-01-white);
  display: inline-block;

  .avatar_image {
    background-color: var(--color-background-hex);
    object-fit: cover;
  }

  .anonymous_profile_icon {
    background: var(--color-cultured-white);
    color: var(--color-utility-interaction-200);
  }

  > * {
    height: 100%;
    width: 100%;
  }

  &.ambassador {
    border: 6px solid #af931c;
  }

  &.xsmall {
    border-width: 1px;
    height: 24px;
    width: 24px;

    .avatar_fallback {
      font-size: 16px;
    }
  }

  &.small {
    border-width: 2px;
    height: 32px;
    width: 32px;

    .avatar_fallback {
      font-size: 16px;
    }
  }

  &.medium {
    border-width: 2px;
    height: 50px;
    width: 50px;

    .avatar_fallback {
      font-size: 20px;
    }
  }

  &.large {
    height: 160px;
    width: 160px;

    .avatar_fallback {
      font-size: 80px;
    }
  }

  .avatar_fallback {
    align-items: center;
    background-color: var(--color-brand-gartner-blue);
    display: flex;
    font-weight: 800;
    justify-content: center;
    opacity: 0.88;
  }

  &.show_badge {
    position: relative;

    .badge {
      position: absolute;
    }

    &.xsmall {
      .badge {
        bottom: -4px;
        height: 12px;
        right: -4px;
        width: 12px;
      }
    }

    &.small {
      .badge {
        bottom: -4px;
        height: 16px;
        right: -4px;
        width: 16px;
      }
    }

    &.medium {
      .badge {
        bottom: -4px;
        height: 18px;
        right: -4px;
        width: 18px;
      }
    }

    &.large {
      .badge {
        bottom: 0;
        height: 32px;
        right: 0;
        width: 32px;
      }
    }
  }
}

.avatar,
.avatar_fallback,
.avatar_image,
.anonymous_profile_icon {
  border-radius: 100%;
}

.verified_badge {
  color: var(--color-brand-gartner-blue);
}
