@import 'utils';

.sentiment {
  display: flex;
  gap: spacing(5);
  align-items: center;
  margin-bottom: spacing(2);
  @include up(tablet) {
    margin-bottom: spacing(6);
  }
}

.icon {
  background-color: var(--color-utility-success-50);
  border-radius: 8px;
  padding: spacing(2);
  svg {
    display: block;
    color: var(--color-black);
  }
}

.container {
  position: relative;
  height: 100%;
  margin-top: spacing(4);
  @include up(tablet) {
    margin-top: spacing(8);
  }
}

.pie_outer {
  max-width: 212px !important;
  max-height: 212px !important;
  position: relative;
  circle {
    stroke-width: 7px;
  }
  circle:first-child {
    stroke: var(--color-utility-success-50);
  }

  circle:last-child {
    stroke: var(--color-brand-surf);
  }
}

.pie_inner,
.pie_middle {
  position: absolute;
}

.pie_inner {
  width: 40% !important;
  height: 40% !important;
  left: 30%;
  top: 29%;

  circle {
    stroke-width: 17px;
  }

  circle:first-child {
    stroke: var(--color-utility-error-50);
  }

  circle:last-child {
    stroke: var(--color-brand-rose);
  }
}

.pie_middle {
  width: 70% !important;
  height: 70% !important;
  left: 15%;
  top: 14%;

  circle {
    stroke-width: 10px;
  }
  circle:first-child {
    stroke: var(--color-neutral-300);
  }

  circle:last-child {
    stroke: var(--color-brand-gartner-blue);
  }
}

.legend {
  display: grid;
  gap: spacing(4);
  height: fit-content;

  .legend_element:nth-child(1)span:first-child:before {
    background-color: var(--color-brand-surf);
  }
  .legend_element:nth-child(2)span:first-child:before {
    background-color: var(--color-brand-gartner-blue);
  }
  .legend_element:nth-child(3)span:first-child:before {
    background-color: var(--color-brand-rose);
  }
}

.legend_element {
  @include flex-column;

  position: relative;
  span {
    padding-left: calc(10px + spacing(2));
    color: var(--color-black);
  }
  span:first-child {
    margin-bottom: spacing(1);
  }

  span:first-child:before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 5px);
    left: 0;
  }
}

.graph {
  @include flex-row;
  gap: spacing(6);
  align-items: center;
  width: 100%;
}
