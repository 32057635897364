@import 'utils';

.text {
  margin-bottom: spacing(8);

  .learn_more {
    color: var(--color-brand-gartner-blue);
    font-weight: 600;
  }
}
