@import 'utils';

.form {
  background-color: var(--color-neutral-200);
  gap: spacing(6);
  @include flex-column;
  margin: 0 spacing(4) spacing(6);
  padding: spacing(6);

  & > * {
    @include flex-row;
    align-items: center;
    gap: spacing(6);
    justify-content: flex-end;
  }

  label {
    align-items: flex-start;
    display: flex;
    justify-content: center;

    textarea {
      border-color: var(--color-neutral-500);
      border-radius: 4px;
      font: inherit;
      min-height: 75px;
      padding: spacing(3);
      transition: border 3.33s ease-in-out;
      width: 100%;

      &:active,
      &:focus {
        border-color: var(--color-utility-interaction-500);
      }
    }
  }
}

.form_comment {
  background-color: transparent;
  margin: 0 0 0 spacing(10);
}

.form_reply {
  background-color: transparent;
  margin: 0 0 0 spacing(20);
}
