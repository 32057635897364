@import 'utils';

.awards_list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: spacing(6);
  justify-content: center;
  list-style: none;
}

.award {
  align-items: center;
  display: flex;
  gap: 6px;
  justify-content: space-between;
  max-width: 230px;
  text-align: center;
  width: 100%;

  &::before,
  &::after {
    height: 124px;
    width: 46px;
  }

  &::before {
    content: url('./media/laurel.svg');
  }

  &::after {
    content: url('./media/laurel.svg');
    transform: scale(-1, 1);
  }

  .award_title {
    color: var(--section-heading-color);
    display: inline-block;
    font-weight: 600;
    margin-bottom: spacing(4);
  }
}
