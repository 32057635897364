@import 'utils';

.link_to_post {
  padding: spacing(6);
}

.title {
  line-height: 1.5;
}

.contributors {
  @include flex-row;
  align-items: center;
  gap: spacing(2);
  margin-top: spacing(3);
}

.avatar_group {
  display: inline-flex;
  align-items: center;
  .avatar:not(:first-child) {
    margin-left: -10px;
  }
}
