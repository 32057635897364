@import 'utils';

.container {
  border: 1px solid var(--color-neutral-500);
  margin: 0;
  padding: spacing(2);
  resize: none;
  white-space: pre-wrap;
  width: 100%;
  font-size: 16px;
}