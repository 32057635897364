@import 'utils';

.interactive_voice {
  max-width: 418px;
  .title {
    text-transform: uppercase;
    margin-bottom: spacing(4);
  }
  .view_all_interactive_vocs {
    color: var(--color-utility-interaction-500);
  }
}

.voice_item {
  @include flex-column;
  justify-content: space-between;
  background-color: var(--color-polar);
  border-left: 4px solid var(--color-picton-blue);
  padding: spacing(6);
  margin-bottom: spacing(6);
  gap: spacing(2);
}

.voice_item_header {
  display: grid;
  column-gap: spacing(3);
  grid-template-columns: auto max-content;
}

.voice_item_date {
  display: flex;
  align-items: center;
  margin-bottom: spacing(2);
  > :first-child::after {
    display: inline-block;
    content: '';
    width: 6px;
    height: 6px;
    background-color: var(--color-neutral-500);
    border-radius: 50%;
    margin: 0px spacing(3) 2px;
  }

  > :last-child {
    color: var(--color-product-text);
  }
}
