@import 'utils';

.content_info {
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(5);
}

.flag_content {
  background-color: var(--color-background-hex);
  box-shadow: var(--box-shadow-medium);
  color: var(--color-foreground-hex);
  width: 250px;

  b {
    font-weight: 700;
  }

  button {
    all: unset;
  }
}

.flag_content_tooltip {
  justify-content: space-between;
}
