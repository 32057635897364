@import 'utils';

.main {
  color: var(--color-product-text);
  margin: spacing(10) auto;
  padding: 0 spacing(6);
  @include up(tablet) {
    @include flex-row;
    gap: spacing(10);
    max-width: 1440px;
  }
}

.content {
  @include flex-column;
  gap: spacing(4);
  @include up(tablet) {
    width: calc(100% - 320px);
  }
}

.peer_driven_insights {
  color: var(--color-neutral-500);
  display: inline-block;
}

.peer_driven_insights,
.about_this_topic {
  margin-bottom: spacing(2);
  text-transform: uppercase;
}

.about {
  background-color: var(--color-neutral-300);
  margin: 0 calc(-1 * spacing(6));
  padding: spacing(6);
  @include up(tablet) {
    background-color: var(--color-background-hex);
    margin: 0;
    padding: 0;
  }

  .about_this_topic {
    color: var(--color-product-text);
    @include up(tablet) {
      display: none;
    }
  }

  .description {
    @include up(tablet) {
      line-height: spacing(8);
    }
  }
}

.featured_omi {
  @include flex-column;
}

.content_info {
  @include flex-column;
  gap: spacing(6);
}

.sidebar {
  @include up(tablet) {
    display: block;
    width: 320px;
  }

  .what_sets_us_apart {
    display: none;
    @include up(tablet) {
      @include flex-column;
    }
  }

  .sidebar_section {
    margin-top: spacing(10);
  }
}

.title {
  margin: 0 0 spacing(6) 0;
}

.spinner {
  padding: 60px;
}
