@import 'utils';

.modal_header {
  display: flex;
  justify-content: space-between;
  margin: spacing(8) spacing(8) 0px spacing(8);
  .button_close {
    border-radius: 50%;
    color: var(--color-product-text);
    padding: spacing(3);
    &:hover {
      color: var(--color-foreground-hex);
      background-color: var(--color-utility-interaction-25);
    }
  }
  &.split {
    border-bottom: 2px solid var(--color-neutral-300);
  }
}

.content {
  flex-grow: 1;
}
